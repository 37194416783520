var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$apollo.loading
        ? _c("k-editor", {
            attrs: {
              collection: _vm.collection,
              theme: _vm.theme,
              content: _vm.content,
              templateName: _vm.templateName,
              snippets: _vm.snippets,
              privileges: _vm.privileges,
              "group-settings": _vm.group.settings,
              corporateIdentity: _vm.corporateIdentityData,
              variablesPluginData: _vm.variablesPluginData,
              chatGPTPluginData: _vm.chatGPTPluginData,
              "page-siblings": _vm.pageSiblings,
              "prev-next-page-siblings": _vm.prevNextPageSiblings,
              "custom-css": _vm.customCssOfParentElements,
              "is-sys-admin": _vm.isSysAdmin,
              language: _vm.language,
              "page-language": _vm.pageLanguage,
              "personalized-data": _vm.personalizedData,
              "forms-data": _vm.formsData
            },
            on: {
              onidle: _vm.onidle,
              "get-editor-data": _vm.saveEditorContentInComponent
            }
          })
        : _vm._e(),
      _vm.isPreviewWindowOpen && _vm.iframeSource && _vm.itemName
        ? _c("modal-preview-page", {
            attrs: {
              "iframe-src": _vm.iframeSource,
              "preview-action-object": _vm.previewActionObject,
              "item-name": _vm.itemName,
              "group-domain": _vm.group.domain,
              "item-path": _vm.itemPath
            }
          })
        : _vm._e(),
      _vm.showReloadPage
        ? _c("modal-reload-page", {
            staticClass: "d-inline-block",
            on: {
              "reload-page": _vm.reloadPage,
              "cancel-reload-page": _vm.cancelReloadPage
            }
          })
        : _vm._e(),
      _vm.showShowUrlModal && _vm.group.domain
        ? _c("modal-show-url", {
            attrs: {
              slug: _vm.slugOfPage,
              "group-domain": _vm.group.domain,
              "slug-path": `${_vm.parentSlugPath}/${_vm.slugOfPage}`,
              pageType: "page",
              itemPath: _vm.itemPath
            },
            on: { "close-modal": _vm.closeShowUrlModal }
          })
        : _vm._e(),
      _c("modal-page-item-error", {
        attrs: {
          "error-message": _vm.errorMessage,
          "error-message-raw": _vm.errorMessageDebugMsg
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }