import gql from 'graphql-tag';

export default gql`
mutation UpdatePageVersion(
  $pageVersionId: Int!,
  $versionNumber: Int!,
  $editorData: jsonb!,
  $displayData: jsonb!,
  $userId: Int!
) {
  update_page_versions(
    where: {
      page_version_id: {_eq: $pageVersionId}
    }, 
    _set: {
      editor_data: $editorData,
      display_data: $displayData,
      version_number: $versionNumber,
      user_id: $userId
    }
  ) {
    affected_rows
  }
}
`;
