import gql from 'graphql-tag';

export default gql`
query getPageDataToPublish($pageId: Int!) {
  pages_by_pk(page_id: $pageId) {
    page_id
    canonical_url
    og_images
    og_title
    og_description
    slug
    hreflang
    group{
      domain
      slug
    }
    listed
    name
    language_id
    description
    thumbnails
    keywords
    published
    published
    searchable
    group_id
    parent_folder {
      corporate_identity
    }
    parent_project {
      corporate_identity
    }
    latest_page_version {
      page_version_id
      display_data
      version_number
    }
  }
}
`;
