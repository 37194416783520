import gql from 'graphql-tag';

export default gql`
mutation updatePageVersionPublishStatus(
  $pageVersionId: Int!,
  $published: Boolean!,
  $userId: Int!
) {
  update_page_versions(
    where: {page_version_id: {_eq: $pageVersionId}},
    _set: {
      published: $published,
      user_id: $userId
    }
  ) {
    affected_rows
    returning {
      updated_at
    }
  }
}
`;
