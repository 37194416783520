import gql from 'graphql-tag';

export default gql`
query getPageVersionDataOfPage($pageId: Int!) {
  pages_by_pk(page_id: $pageId) {
    published
    language {
      handle
    }
    latest_page_version {
      page_version_id
      editor_data
      display_data
      version_number
      published
    }
  }
}
`;
