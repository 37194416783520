import gql from 'graphql-tag';

export default gql`
query getAllSiblingPagesOfCurrentPage($pageId: Int!) {
  pages_by_pk(page_id: $pageId) {
    parent_project {
      project_id
      child_pages {
        page_id
        slug
        order_index
        name
        description
        thumbnails
        published
        listed
        keywords
        pages_tags {
          tag {
            name
          }
        }
      }
    }
    parent_folder {
      folder_id
      child_pages {
        page_id
        slug
        order_index
        name
        description
        thumbnails
        published
        listed
        keywords
        pages_tags {
          tag {
            name
          }
        }
      } 
    }
  }
}
`;
