import gql from 'graphql-tag';

export default gql`
mutation updatePagePublishFlag(
  $pageIds: [Int!], 
  $isPublished: Boolean!
) {
  update_pages(
    where: {page_id: {_in: $pageIds}}, 
    _set: {published: $isPublished}
  ) {
    affected_rows
  }
}
`;
