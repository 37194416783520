import gql from 'graphql-tag';

export default gql`
query getCustomCssOfFoldersAndProjects($folderIds: [Int!], $projectIds: [Int!]) {
  folders(where: {folder_id: {_in: $folderIds}}) {
    custom_css
  }
  projects(where: {project_id: {_in: $projectIds}}) {
    custom_css
  }
}
`;
