import gql from 'graphql-tag';

export default gql`
# query getPublishStatusOfLatestPageVersionsByPageId(
#   $pageId: Int!
# ) {
#   pages_by_pk(page_id: $pageId) {
#     latest_page_version {
#       page_version_id
#       version_number
#       published
#     }
#   }
# }
query getPublishStatusOfLatestPageVersionsByPageId($pageId: Int!) {
  pages_by_pk(page_id: $pageId) {
    page_versions(order_by: {page_version_id: desc}, limit: 1) {
      page_version_id
      version_number
      published
    }
  }
}
`;
