import gql from 'graphql-tag';

export default gql`
query getFormsByPath($itemPath: String!) {
  getFormsByPath(object: { item_path: $itemPath }) {
    form_id
    description
    name
    form_data
  }
}
`;
