import gql from 'graphql-tag';

export default gql`
query getTemplateCollectionOfPage($pageId: Int!, $groupId: Int) {
  pages(where: {page_id: {_eq: $pageId}}) {
    parent_folder {
      corporate_identity
      plugins(where: {handle: {_in: ["plugin_chatGPT","plugin_variables"]}, enabled: {_eq: true}}) {
        handle
        settings
      }
    }
    parent_project {
      corporate_identity
      plugins(where: {handle: {_in: ["plugin_chatGPT","plugin_variables"]}, enabled: {_eq: true}}) {
        handle
        settings
      }
      theme {
        name
      }
    }
    template_id
    template {
      data
      name
      templates_snippets(where: {
        _or: [
          {snippet: {group_id: {_is_null: true}}},
          {snippet: {group_id: {_eq: $groupId}}}
        ]
      }) {
        snippet {
          data
        }
      }
      template_collections_templates {
        template_collection {
          name
        }
      }
    }
  }
}
`;
