import gql from 'graphql-tag';

export default gql`
query getPublishOfParentProject($pageId: Int!) {
  pages_by_pk(page_id: $pageId) {
    parent_project {
      published
    }
  }
}
`;
